export const USER_AGREEMENT_TEXT = `
ПУБЛИЧНАЯ ОФЕРТА
 
 Для целей применения и толкования настоящей публичной оферты (далее – «Оферта») используются определенные ниже основные термины (если в Оферте прямо не указано иное). В тексте Оферты эти термины могут быть указаны с большой или маленькой буквы в единственном или множественном числе, а также в виде сокращений.
  
 1.     ТЕРМИНЫ И ОПРЕДЕЛЕНИЯ
 Продавец – Общество с ограниченной ответственностью «Кошер-Гурмэ», реквизиты которого приведены в разделе 12 настоящей Оферты, собственник Товара, размещенного на Сайте.
 Покупатель – дееспособное физическое лицо, принимающее условия настоящего Договора и размещающее Заказы на Сайте, либо указанное в качестве получателя Товара.
 Агент – Общество с ограниченной ответственностью "СЕЛЛ1", адрес местонахождения Москва г, пр-кт Ленинградский, дом 47, строение 2, помещение 28А, ИНН: 9714016972, КПП: 771401001, ОГРН: 1237700539405, уполномоченное Продавцом на прием платежей за Товары от Покупателей для последующего перечисления Продавцу.
 Сайт – Интернет-сайт, принадлежащий Продавцу, имеющий адрес в сети Интернет https://koshergurme.ru/. На Сайте представлены Товары (Услуги), предлагаемые Продавцом своим Покупателям для оформления Заказов, а также условия оплаты и доставки этих Заказов Покупателям.
 Аккаунт - созданная с помощью мобильного устройства или компьютера учетная запись Покупателя, которая представляет собой совокупность данных о Покупателе, данные его персонального личного кабинета, через который можно управлять настройками страницы, менять информацию на странице, регулировать порядок получения уведомлений и совершать иные действия, предусмотренные функционалом Сайта.
 Товар - объект Договора, перечень наименований ассортимента, представленный на Сайте. Под товаром в настоящей Оферте может также пониматься услуга или работа в случае, если таковая предлагается к оказанию Продавцом Покупателю на Сайте.
 Заказ — должным образом оформленный запрос Покупателя на приобретение и доставку по указанному адресу перечня Товаров, выбранных на Сайте. Заказ может быть оформлен как в целях заключения договора розничной купли-продажи (договора оказания услуг) с Продавцом, так и для осуществления обмена или замены Товаров по ранее заключенному договору в случаях, предусмотренных законодательством РФ.
 Получатель – Покупатель или уполномоченное им на получение Заказа третье лицо.
 Отмена Заказа или Товара – техническое действие Продавца, не подразумевающее отказ Продавца от исполнения договора, которое осуществляется на Сайте и констатирует факт того, что некоторые Товары в составе Заказа (отмена Товара) либо все Товары в составе Заказа (отмена Заказа) не переданы Покупателю по текущему Заказу. Продавец обязан уведомить зарегистрированных Покупателей обо всех случаях отмены, в том числе, в случаях отказа от Товаров по инициативе Покупателя, путем направления электронного сообщения об отмене по электронному адресу, указанному Покупателем при регистрации.
 Служба доставки - третье лицо, оказывающее по договору с Продавцом услуги по доставке Заказов Покупателем.
 Верификация - процедура, позволяющая установить реальность и принадлежность определенного номера телефона конкретному физическому лицу, в результате которой создается Аккаунт), привязанный к верифицированному номеру телефона.
  
 В настоящей Оферте могут быть использованы термины, не определенные в данном разделе. В этом случае толкование такого термина производится в соответствии с текстом Оферты. В случае отсутствия однозначного толкования термина в тексте следует руководствоваться толкованием термина, определенным в первую очередь на Сайте, а затем сложившимся (общеупотребительным) языком в сети Интернет и других источниках.
  
 2.     ОБЩИЕ ПОЛОЖЕНИЯ
 2.1.          Настоящая Оферта является официальным предложением Продавца в адрес любого дееспособного физического лица заключить с Продавцом договор купли-продажи Товара (договор оказания Услуг) на условиях, определенных в настоящей Оферте.
 2.2.          Отношения между Покупателем и Продавцом регулируются положениями Гражданского кодекса Российской Федерации (далее – ГК РФ), Законом РФ от 07.02.1992 N 2300-1 "О защите прав потребителей" (далее – Закон о защите прав потребителей), Правилами продажи товаров дистанционным способом (Постановление Правительства РФ от 27.09.2007 N 612), а также нормативно-правовыми актами РФ, регулирующими оборот отдельных видов товаров (если применимо)
 2.3.          Публичная оферта признается акцептованной Покупателем с момента прохождения процедуры Верификации и оформления Покупателем Заказа на Сайте.  В случае несогласия с условиями Публичной оферты Покупатель обязан немедленно прекратить использование и покинуть Сайт.
 2.4.          Покупатель соглашается с тем, что Продавец вправе поручить исполнение Договора третьему лицу, оставаясь ответственным за его исполнение.
 2.5.          Продавец оставляет за собой право вносить изменения в настоящую Оферту, в связи с чем, Покупатель обязуется регулярно отслеживать изменения в Оферте, размещенной на Сайте
 2.6.          Покупатель соглашается с полным текстом Оферты со всеми приложениями и дополнительными разделами на Сайте, создавая Аккаунт, оформляя Заказы, либо путем прохождения Верификации по номеру телефона, как это описано в разделе 4 настоящей Оферты.
 2.7.          Покупатель соглашается с условиями продажи выбранных им Товаров (условия индивидуального договора купли-продажи) нажатием кнопки «Подтвердить заказ» на последнем этапе оформления Заказа на Сайте. Совершение указанных действий является фактом, подтверждающим заключение договора между Покупателем и Продавцом
 2.8.          В случае проведения стимулирующих мероприятий - акций, в условиях акций, размещаемых на Сайте, могут быть установлены специальные положения, регулирующие порядок оформления заказа и возврата товара. При этом условия акций являются неотъемлемой частью настоящей Оферты, и подлежат применению для лиц, участвующих в акциях. Оформление акционного Заказа и/или выполнение иных условий участия в акции, означает согласие Покупателя с условиями соответствующей акции.
 2.9.          Регистрируясь на Сайте, Покупатель в соответствии с ч. 1 ст. 18 Федерального закона «О рекламе», предоставляет свое предварительное согласие на получение сообщений рекламного характера (в форме СМС и/или пуш-уведомлений и/или посредством приложений и/или мессенджеров для смартфонов и/или телефонных звонков и/или иным образом на указанный Покупателем номер телефона, адрес электронной почты) от Продавца и его партнеров. В случае нежелания получать сообщения рекламного характера Покупателю следует изменить настройки подписки в соответствующем разделе Аккаунта.
  
 3.     ГАРАНТИИ ПОКУПАТЕЛЯ.
 3.1.          Принимая настоящую Оферту, Покупатель безусловно и безоговорочно:
 3.1.1.     Гарантирует, что денежные средства, которые используются Покупателем не добыты преступным путем и имеют легальный источник происхождения, в противном случае Покупатель обязан воздержаться от совершения любых действий на Сайте.
 3.1.2.     Гарантирует, что он является законными владельцем и имеет право использовать все денежные суммы, и что совершаемые им покупки не нарушают права любых третьих лиц или действующее законодательство.
 3.1.3.     Гарантирует, что он не будет совершать действий с целью получить несанкционированный доступ к Сайту, и любая попытка сделать это или содействие другим Покупателям или третьим лицам в этом (включая распространение инструкций, программного обеспечения и инструментов для этой цели) приведет к расторжению настоящего Оферты с Покупателем. Также, Продавец в этом случае, оставляет за собой право принять любые иные действия против Покупателя, включая, но не ограничиваясь, направлением информации в правоохранительные или иные компетентные органы.
 3.1.4.     Признает и соглашается, что с целью обеспечения безопасности Аккаунтов Покупателей и противодействия легализации (отмыванию) денежных средств, в рамках реализации внутренней политики безопасности, Продавец по умолчанию применяет средства внутреннего контроля и программы для его осуществления, собирает и хранит любую информацию о существенных фактах относительно Покупателей и их покупок, включая личную информацию о Покупателях, предоставленную ими при регистрации Аккаунта, или запрошенную Продавцом при осуществлении покупок, а также косвенную информацию, в том числе IP-адреса, информацию об используемой операционной системе, конфигурации программного обеспечения, и другую информацию, собранную с применением технологии «cookies» («куки») для создания статистической отчетности.
 3.1.5.     Признает и соглашается с тем, что Продавец может запросить у Покупателя любую дополнительную идентификационную информацию и дополнительные документы в любое время по просьбе любого компетентного органа или при применении любого действующего законодательства или регулирования любой страны, включая законодательство о противодействии отмыванию (легализации) денежных средств, полученных преступным путем, или о противодействии финансированию терроризма. В указанном случае, если Покупатель не предоставит, предоставит в неполном объеме или недостоверную запрашиваемую информацию и документы, Продавец имеет право в одностороннем порядке расторгнуть настоящую Оферту с Покупателем.
 3.1.6.     Соглашается с тем, что Продавец может автоматически генерировать электронные письма для целей информирования и рекламы. Соглашается, что будет получать эти сообщения и что эти сообщения могут быть типовыми.
 3.1.7.     Понимает и соглашается, что любые претензии и запросы принимаются и обслуживаются только через службу поддержки. Покупатель понимает и согласен, что ни на форумах, ни в социальных сетях, ни где-либо еще, кроме официального email адреса, указанного в разделе «Контактные данные Продавца» и тикетов в Аккаунте, его вопросы и претензии не будут рассмотрены.
 3.1.8.     Понимает и соглашается с тем, что Продавец может, приостанавливать, ограничивать или прекращать доступ конкретного Покупателя или всех Покупателей сразу ко всем или к любому из разделов Сайта в любое время по любой причине или без объяснения причин, с предварительным уведомлением или без такового, не отвечая за любой вред, который может быть причинен Покупателю таким действием.
 3.1.9.     Принимает и соглашается с тем, что посещение Сайта, приобретение и доставка Покупателю конкретного Товара могут быть признаны незаконными на территории страны, резидентом которой является Покупатель.
 3.1.10.  Покупатель несет ответственность за невыполнение законов своей страны при посещении Сайта и попытке приобрести Товар, если таковой запрещен законодательством на территории страны, резидентом которой он является.
 3.1.11.  Принимает и соглашается с тем, что условия доставки Товара могут иметь экспортные и лицензионные ограничения по осуществлению такой доставки.
  
 4.     ВЕРИФИКАЦИЯ.
 4.1.          Для регистрации на Сайте и/или оформления Заказа Покупателю необходимо пройти следующую процедуру Верификации по номеру телефона:
 a)     ввести номер телефона в специальное поле, после чего нажать кнопку «отправить код»;
 b)     владельцу телефона посредством смс-сообщений и/или пуш-уведомлений и/или посредством приложений и/или мессенджеров для смартфонов и/или иным образом на, указанный Покупателем номер телефона, направляется индивидуальный код, который он вводит в поле, предназначенное для кода;
 c)     при совпадении отправленного и введенного кода Верификация признается успешно пройденной – устанавливается принадлежность номера телефона определенному физическому лицу, чьи данные содержатся в Аккаунте и/или в информации о Покупателе на стадии оформления Заказа.
 4.2.          Если у Покупателя уже есть Аккаунт на Сайте, то после успешной Верификации происходит автоматический вход в этот Аккаунт. Иначе Покупателю предлагается ввести адрес электронной почты и пароль от существующего Аккаунта, либо создать новый Аккаунт.
 4.3.          В случае изменения номера телефона Покупатель может воспользоваться процедурой изменения номера телефона в Аккаунте с использованием функционала Аккаунта на Сайте.
 4.4.          Если Покупатель использовал один и тот же номер телефона для разных Аккаунтов, то, при первичной Верификации, он вправе выбрать один Аккаунт и указать логин и пароль от этого Аккаунта, который становится основным.
 4.5.          Покупатель также вправе зайти в Аккаунт путем ввода логина (адрес электронной почты) и пароля от Аккаунта, однако для оформления Заказов требуется прохождение процедуры Верификации номера телефона Покупателя, описанной в данном разделе.
 4.6.          Покупатель обязуется не сообщать третьим лицам логин и пароль, номер телефона и индивидуальные коды.
 4.7.          Одноразовый код, направляемый для Верификации номера телефона, является простой электронной подписью, которой Покупатель подтверждает принадлежность указанного номера телефона Покупатель и его согласие с настоящей Офертой.
 4.8.          Проходя Верификацию, Покупатель соглашается с получением сообщений сервисного характера, направляемых на адрес электронной почты, указанный при регистрации, и/или посредством смс-сообщений и/или пуш-уведомлений и/или иным образом на номер телефона, указанный Покупателем при регистрации и/или оформлении заказа, о состоянии заказа, условиях доставки, товарах в разделе «Корзина» и/или добавленных Покупателем в "Избранное", а также просьба Продавца оставить отзыв о приобретенном Товаре. Отказ Покупателя от получения указанных сообщений невозможен по техническим причинам.
 4.9.          Покупатель соглашается с тем, что к его Аккаунту могут быть применены любые ограничения (в том числе блокировка), связанные с безопасностью или нарушением Покупателем настоящей Оферты, а также по причинам технического характера. Кроме того, принимая настоящую Оферту, Покупатель соглашается с тем, что ограничения на его Аккаунте могут быть применены в любое время без объяснения причин и без уведомления Покупателя.
  
 5.     ОФОРМЛЕНИЕ И СРОКИ ВЫПОЛНЕНИЯ ЗАКАЗА.
 5.1.          Заказ может быть оформлен Покупателем самостоятельно на Сайте путем осуществления следующей последовательности действий:
 a)     Покупатель выбирает товары и добавляет их в раздел «Корзина».
 b)     Переходит в раздел «Корзина» и нажимает «Перейти к оформлению».
 c)     Указывает свои данные. Если Покупатель не авторизован, то проходит процедуру авторизации с помощью номера телефона или почты.
 d)     Выбирает способ доставки и указывает адрес.
 e)     Выбирает способ оплаты и нажимает «Оплатить онлайн» или «Оформить заказ»
 f)      Оплачивает заказ, если выбрана форма оплаты онлайн
 5.2.          При оформлении Заказа Покупатель должен пройти процедуру Верификации и указать следующую информацию:
 ·       Ф.И.О. и номер телефона Покупателя;
 ·       Ф.И.О. и номер телефона Получателя Заказа (если он отличается от Покупателя);
 ·       Адрес доставки/самовывоза Заказа.
 5.3.          После оформления Заказа Покупателю предоставляется информация об ожидаемой дате доставки Заказа. Эта дата означает срок, в который Служба доставки будет готова вручить Заказ Покупателю. Указанная дата зависит от наличия заказанных Товаров на складе, времени, необходимого на обработку Заказа и сроков доставки Заказа, выбранной при оформлении Заказа Службой доставки.
 5.4.          Если Покупателем оформлен Заказ на Товар в количестве, превышающем имеющееся на складе у Продавца необходимого количество заказанного Товара, Продавец информирует об этом Покупателя посредством направления электронного сообщения. Сообщение направляется по электронному адресу, указанному при регистрации. Покупатель вправе согласиться принять Товар в количестве, имеющемся в наличии у Продавца, либо отменить данную позицию Товара из Заказа. В случае неполучения ответа Покупателя в течение 14 дней Продавец оставляет за собой право отменить данный Товар из Заказа.
 5.5.          Продавец вправе отменить Заказы Покупателя, содержащие Товары, от которых ранее Покупатель отказался 5 и более раз, указав причины, не связанные с наличием недостатков в этих Товарах.
 5.6.          Информация о наличии Товара на складе Продавца и ожидаемый срок поставки Товара на склад Продавца указываются на Сайте на странице Товара. Конечные сроки получения Заказа Покупателем зависят от адреса и региона доставки, работы конкретной Службы доставки, и напрямую не зависят от Продавца.
 5.7.          Все информационные материалы о Товарах, представленные на Сайте, носят справочный характер и не могут в полной мере передавать достоверную информацию о свойствах и характеристиках Товара, включая цвета, размеры и формы. В случае возникновения у Покупателя вопросов, касающихся свойств и характеристик Товара, перед оформлением Заказа, Покупатель должен обратиться к Продавцу по контактным данным, указанным на Сайте.
 5.8.          При оформлении некоторых Заказов Покупатель может выбрать способ формирования Заказа в одно или несколько отправлений.
 5.9.          Если в соответствии с требованиями законодательства необходима проверка работоспособности Товара перед передачей его Покупателю, такая проверка осуществляется на складе Продавца до передачи Товара в Службу Доставки.
 5.10.       Отмена Заказа или Товара.
 5.10.1.  Отмена Заказа или Товара производится по инициативе Покупателя либо Продавца. Продавец отменяет Заказ в случае существенного изменения обстоятельств, из которых Покупатель и Продавец исходили при оформлении Заказа, и которые сделали исполнение Заказа невозможным.
 5.10.2.  Если исполнение заказа со стороны Продавца стало невозможным по причинам, не зависящим от воли Покупателя или Продавца (в случае закрытия региона доставки Службой доставки, изменения таможенных правил и процедур, закрытия Пункта выдачи заказов, выбранного Покупателем в качестве способа доставки, и других обстоятельств, препятствующих передаче Товара согласованным способом, в том числе в случае утери заказа при доставке, отсутствии Товара на складе, отсутствия Товаров на складе в виду выявленного при обработке заказа брака, пересорта или неработоспособности Товара и т.п.), Продавец отменяет заказ и уведомляет Покупателя, а Покупатель вправе согласовать новые условия доставки Товаров путем оформления нового Заказа, если на момент оформления заказа Товар есть в продаже.  Если на момент оформления заказа цена товара увеличилась, а предыдущий заказ был отменен не по причине брака, компенсация разницы в цене на день оформления нового Заказа не начисляется.
 5.10.3.  Покупатель вправе изменить решение о покупке отказаться от заказанного Товара в любое время до его получения (доставки).
 5.10.4.  Покупатель вправе полностью или частично отказаться от Товаров Заказа в момент получения (доставки).
 5.10.5.  Покупатель в любом случае не вправе отказаться от Товара надлежащего качества, имеющего индивидуально-определенные свойства, если указанный Товар может быть использован исключительно приобретающим его Покупателем.
 5.10.6.  По предоплаченному Заказу стоимость доставки подлежит возврату в случае, если Заказ был отменен или не доставлен.
 5.10.7.  С текущим статусом Заказа Покупатель может ознакомиться в Аккаунте.
 5.10.8.  При полной или частичной отмене Заказа или Товара до момента его получения и в момент передачи Покупателю, автоматически будет оформлен возврат на карту, с которой производилась оплата.
  
 6.     ДОСТАВКА ТОВАРА
 6.1.          Доставку осуществляет Служба доставки, привлеченная Продавцом и выбранная Покупателем либо Агент, в том числе, с привлечением третьих лиц. Способы доставки Товаров указаны на Сайте. Возможность доставки до адреса получателя определяется в момент оформления заказа. Согласованным способом доставки считается способ, выбранный Покупателем из доступных способов доставки при оформлении Заказа.
 6.2.          Территория доставки некоторых видов Товаров может быть ограничена. Перечень товаров, доставка которых ограничена, содержится разделе «Доставка» на Сайте.
 6.3.          При наличии у Покупателя оснований, дающих ему право на получение товаров, свободная доставка которых ограничена, Покупатель вправе обратиться в Службу поддержки для решения вопроса в индивидуальном порядке.
 6.4.          Продавец приложит все усилия для соблюдения сроков доставки, указанных на Сайте, тем не менее, задержки в доставке возможны ввиду непредвиденных обстоятельств, произошедших не по вине Продавца.
 6.5.          Риск случайной гибели или случайного повреждения Товара переходит к Покупателю с момента передачи ему Заказа.
 6.6.          В случае выбора Покупателем в качестве Службы доставки международных почтовых отправлений Почты России риск случайной гибели или случайного повреждения Товара лежит на Продавце в полном объеме до передачи Товара в Службу доставки. После передачи Товара в указанную Службу доставки ответственность Продавца определяется в пределах, установленных международным почтовым законодательством (Всемирной почтовой конвенцией от 12 августа 2008 года).
 6.7.          В случае выбора Покупателем способа доставки «Оставить посылку у двери» риск случайной гибели или случайного повреждения Товара лежит на Покупателе в момент отправки Покупателю уведомления о доставке Товара без непосредственной передачи Товара. Продавец не несет ответственности за отсутствие Товара в месте его доставки после направления соответствующего уведомления.
 6.8.          Стоимость доставки каждого Заказа рассчитывается индивидуально, исходя из его веса, региона и способа доставки, а иногда и формы оплаты, и указывается на последнем этапе оформления Заказа на Сайте.
 6.9.          При доставке Заказ вручается Покупателю либо лицу, указанному в качестве Получателя Заказа. При невозможности получения Заказа, оформленного за наличный расчет, указанными выше лицами, Заказ вручается лицу, готовому предоставить сведения о Заказе (номер отправления и/или ФИО Получателя), а также оплатить стоимость Заказа в полном объеме лицу, осуществляющему доставку Заказа.
 6.10.       Во избежание случаев мошенничества, а также для выполнения взятых на себя в пункте 6.9 Оферты обязательств, при вручении предоплаченного Заказа лицо, осуществляющее доставку Заказа, вправе затребовать документ, удостоверяющий личность Получателя.
 6.11.       При покупке физическим лицом ювелирных изделий из драгоценных металлов и драгоценных камней на сумму свыше 40 000 рублей, а также при использовании персонифицированного электронного средства платежа для совершения покупки на сумму свыше 100 000 рублей в соответствии с пунктом 1.4-2 статьи 7 Федерального закона от 07.08.2001 № 115-ФЗ «О противодействии легализации (отмыванию) доходов, полученных преступным путем, и финансированию терроризма» лицо, осуществляющее доставку Заказа обязано запросить  документ, удостоверяющий личность Получателя и провести процедуру идентификации путем заполнения специальной формы Анкеты для передачи сведений в Росфинмониторинг, а Получатель обязан предоставить документ, удостоверяющий личность по требованию.
 6.12.       При передаче Заказа Покупатель в присутствии лица, осуществляющего доставку Заказа, должен проверить внешний вид и упаковку Заказа, количество Товаров в Заказе, внешний вид и упаковку Товаров, комплектность, ассортимент. Вскрытие индивидуальной упаковки ювелирных изделий из драгоценных металлов и драгоценных камней должны быть без повреждения целостности этикеток, ярлыков и пломб, прикрепленных к изделию, а примерка изделий осуществляется только в присутствии лица, доставившего или выдавшего Заказ.
 6.13.       Сроки, отведенные для получения Заказа Покупателем, ограничены и указываются при оформлении Заказа, на этапе выбора соответствующего способа доставки. Дополнительная информация представлена в разделе «Доставка».
 6.14.       Неполучение Заказа в указанные в п. 6.13 Оферты сроки считается отказом Покупателя от договора купли-продажи и является основанием для отмены Заказа Продавцом. Если неполученный Заказ был предоплачен, денежные средства возвращаются Покупателю в порядке, предусмотренном п. 8.3.2. Оферты.
  
 7.     ОПЛАТА ТОВАРА.
 7.1.          Цена Товара указывается на Сайте. В случае неверного указания цены заказанного Покупателем Товара, Продавец при первой возможности информирует об этом Покупателя для подтверждения Заказа по исправленной цене либо отмене Заказа. При невозможности связаться с Покупателем данный Заказ считается отмененным. Если Заказ был оплачен, Продавец возвращает Покупателю оплаченную за Заказ сумму на банковскую карту, с которой был осуществлен платеж либо осуществляет возврат денежных средств в порядке предусмотренном п. 8.3.2. Оферты.
 7.2.          Цена Товара, указанная на Сайте, может быть изменена Продавцом в одностороннем порядке. Цена Товара действительна на момент нажатия кнопки "Подтвердить заказ" на последнем этапе оформления Заказа. При этом цена на заказанный Покупателем Товар изменению не подлежит.
 7.3.          Способы оплаты Товара указаны на Сайте в разделе «Способы оплаты». Согласованным способом оплаты считается способ, выбранный Покупателем из доступных способов оплаты при оформлении Заказа. В случае оплаты Товара с использованием банковских платежных карт, оплата производится на расчетный счет Агента для последующего перечисления поступивших средств Агентом на расчетный счет Продавца.
 7.4.          Для оплаты товаров из Заказа на сумму более 100 000 рублей могут быть использованы все способы оплаты, кроме наличных денежных средств.
 7.5.          При предоплате Товаров Заказ принимается в обработку только после зачисления денежных средств Покупателя на расчетный счет Агента. При этом Товар, помещенный Покупателем в раздел «Корзина», подлежит оплате в тот же день. В случае, если оплата за Товар не осуществлена в указанный срок, Продавец не может гарантировать доступность Товара на складе Продавца, вследствие чего могут увеличиться сроки обработки Заказа либо Заказ может быть отменен Продавцом полностью или частично.
 7.6.          В соответствии с положением ЦБ РФ "Об эмиссии банковских карт и об операциях, совершаемых с использованием платежных карт" от 24.12.2004 №266-П операции по банковским картам совершаются держателем карты либо лицом, уполномоченным на основании доверенности, оформленной в соответствии с законодательством.
 7.7.          При совершении оплаты Товара с помощью банковской карты в Пункте выдачи заказов Покупатель должен предъявить документ, удостоверяющий личность, за исключением случая оплаты неименной банковской картой.
 7.8.          Оплата заказа с помощью банковских карт осуществляется Покупателем самостоятельно на Сайте путем переадресации на платежную форму Банка и осуществления последовательности действий, указанных в платежной форме.
 7.9.          При осуществлении оплаты с использованием банковской карты происходит переадресация с сайта Продавца на страницу, содержащую платежную Банка для указания реквизитов банковской карты Покупателя.
 7.10.       Авторизация операций по банковским картам осуществляется банком. Если у банка есть основания полагать, что операция носит мошеннический характер, то банк вправе отказать в осуществлении данной операции. Мошеннические операции с банковскими картами попадают под действие статьи 159 УК РФ. Согласно ст.159 УК РФ мошенничеством считается хищение чужого имущества или приобретение права на чужое имущество путем обмана или злоупотребления доверием и наказывается штрафом в размере до 120 000 рублей или в размере заработной платы или иного дохода осужденного за период до 1 года, либо обязательными работами на срок до 360 часов, либо исправительными работами на срок до 1 года, либо арестом на срок до 4 месяцев, либо ограничением свободы на срок до 2 лет, либо принудительными работами на срок до 2 лет, либо лишением свободы на срок до 2 лет.
 7.11.       Во избежание случаев различного рода неправомерного использования банковских карт при оплате, все Заказы, оформленные на Сайте и предоплаченные банковской картой, могут быть проверены Продавцом или Агентом. Продавец оставляет за собой право без объяснения причины аннулировать Заказ. Стоимость Заказа возвращается на банковскую карту владельца.
 7.12.       Прием и обработка платежей с использованием банковских карт проводится провайдером электронных платежей. Продавец и Агент не осуществляют обработку, в том числе сбор и хранение данных банковских карт Покупателей.
 7.13.       Осуществляя платеж банковской картой, а также при оплате заказа при получении, Покупатель соглашается с направлением ему кассового чека в электронной форме (ссылки на кассовый чек с возможностью скачать его в формате PDF) на электронную почту, либо на Верифицированный номер телефона в случае отсутствия информации об электронной почте в Аккаунте. Кассовый чек в печатной форме в таком случае не предоставляется.
 7.14.       Продавец вправе предоставлять Покупателю скидки на Товар и устанавливать программу бонусов. Виды скидок, бонусов, порядок и условия начисления указаны на Сайте в соответствующих тематических блоках раздела "Служба поддержки" и могут быть изменены Продавцом в одностороннем порядке.
 7.15.       Продавец вправе устанавливать скидки в целях продвижения того либо иного способа оплаты или доставки Товара. При этом Продавец может ограничивать условия действия скидок, ограничивать их предельный размер. Подробные условия предоставления коммерческих скидок представлены на Сайте. Скидка может предоставляться на последующий Заказ также в целях компенсации возможного увеличения стоимости Товара при оформлении нового Заказа в случае замены Товара или обмена на аналогичный и в других случаях, когда, согласно настоящей Офертой, требуется пересчитать стоимость Товара.
 7.16.       При проведении маркетинговых мероприятий, предполагающих вложение каких-либо объектов в Заказы Покупателя, доставка указанных вложений осуществляется вместе с Заказом Покупателя.
 7.17.       В стоимость Заказа не включены таможенные пошлины. Условия оплаты и размер таможенных пошлин определяются таможенным законодательством той страны, куда осуществляется доставка Заказа.
  
 8.     ВОЗВРАТ И ОБМЕН ТОВАРА
 8.1.          Возврат и обмен Товаров надлежащего качества.
 8.1.1.     После получения Заказа, Покупатель праве вернуть или обменять Товар надлежащего качества на аналогичный Товар, если указанный Товар не подошел по форме, габаритам, фасону, расцветке, размеру или комплектации в следующие сроки:
 •          В течение 1 дня можно вернуть любые товары. Покупатель может проверить заказ и вернуть как товары с повреждениями или браком, так и товары, которые не подошли или не понравились.
 •          Для Товаров, кроме приобретенных у международных продавцов - в течение 14 дней, не считая дня передачи Товара Покупателю;
 •          Для Товаров, приобретенных у международных продавцов - в течение 7 календарных дней после получения Заказа;
 •          Для Товаров, указанных ниже - в течение 7 календарных дней с даты получения Заказа:
 1)     Текстильные товары, отпускаемые на метраж
 2)     Животные и растения
 3)     Автомобили и другие номерные агрегаты
 4)     Цифровые товары
 5)     Непериодические издания (книги, брошюры, альбомы, картографические и нотные издания, листовые издания, календари, буклеты, издания, воспроизведенные на технических носителях информации)
 6)     Чулочно-носочные изделия, бельевые товары
 7)     Предметы личной гигиены (зубные щетки, расчески, заколки, бигуди для волос, парики, шиньоны и другие аналогичные товары)
 8)     Парфюмерно-косметические товары
 9)     Антиквариат и предметы искусства
 8.1.2.     Обмен или возврат Товара надлежащего качества проводится, если указанный Товар не был в употреблении, сохранены его товарный вид, потребительские свойства, пломбы, фабричные ярлыки и т.д., а также при наличии документа, подтверждающего факт и условия покупки указанного Товара.  При обнаружении следов эксплуатации Товара, Продавец оставляет за собой право отказать в возврате денежных средств по данному основанию.
 8.1.3.     При возврате Покупателем Товара надлежащего качества Продавец обеспечивает возврат стоимости возвращенного Товара, за исключением расходов на доставку от Покупателя возвращенного Товара в срок не позднее чем через 10 дней с даты возврата Товара на склад Продавца.
 8.1.4.     Не подлежат обмену и возврату Товары надлежащего качества следующих категорий:
 ·          Продукты питания
 ·          Ювелирные и другие изделия из драгоценных металлов и (или) драгоценных камней, ограненные драгоценные камни;
 ·          Лекарственные средства;
 ·          Цифровые товары в электронном виде.
 8.1.5.     Обмен Товара надлежащего качества осуществляется путем возврата Товара Продавцу с последующей отменой Заказа или Товара, затем оформления нового Заказа. При этом отмена является техническим действием и не подразумевает отказ Продавца от исполнения договора. Денежные средства возвращаются Покупателю в полном объеме. Если на момент обращения Покупателя аналогичный Товар отсутствует в продаже у Продавца, Покупатель вправе отказаться от исполнения договора купли-продажи и потребовать возврата уплаченной за указанный Товар денежной суммы. Продавец возвращает денежную сумму за возвращенный Товар в установленный Законом срок.
 8.1.6.     При покупке комплекта товаров по акции, в рамках которой была предоставлена скидка на один из товаров или все товары в комплекте, товары надлежащего качества могут быть возвращены только в составе комплекта. Возврат отдельных товаров из комплекта невозможен, если иное не предусмотрено в правилах проведения акции.
 8.2.          Возврат и обмен Товаров ненадлежащего качества.
 8.2.1.     Покупатель может возвратить Товар ненадлежащего качества изготовителю или Продавцу и потребовать возврата уплаченной денежной суммы, а также потребовать замены Товара ненадлежащего качества либо устранения недостатков в следующие сроки:
       I.          Для технически сложных товаров - в течение 15 дней с даты покупки. По истечении указанного срока возврат и обмен возможен в одном из следующих случаев:
 1)     обнаружение существенного недостатка товара;
 2)     нарушение сроков устранения недостатков товара;
 3)     невозможность использования товара в течение каждого года гарантийного срока в совокупности более чем 30 дней вследствие неоднократного устранения его различных недостатков.
 8.2.2.     Перечень технически сложных товаров  (установлен Постановлением Правительства РФ от 10 ноября 2011 г. № 924 г):  
 1)     Легкие самолеты, вертолеты и летательные аппараты с двигателем внутреннего сгорания (с электродвигателем)
 2)     Автомобили легковые, мотоциклы, мотороллеры и транспортные средства с двигателем внутреннего сгорания (с электродвигателем), предназначенные для движения по дорогам общего пользования
 3)     Тракторы, мотоблоки, мотокультиваторы, машины и оборудование для сельского хозяйства с двигателем внутреннего сгорания (с электродвигателем)
 4)     Снегоходы и транспортные средства с двигателем внутреннего сгорания (с электродвигателем), специально предназначенные для передвижения по снегу
 5)     Суда спортивные, туристские и прогулочные, катера, лодки, яхты и транспортные плавучие средства с двигателем внутреннего сгорания (с электродвигателем)
 6)     Оборудование навигации и беспроводной связи для бытового использования, в том числе спутниковой связи, имеющее сенсорный экран и обладающее двумя и более функциями
 7)     Системные блоки, компьютеры стационарные и портативные, включая ноутбуки, и персональные электронные вычислительные машины
 8)     Лазерные или струйные многофункциональные устройства, мониторы с цифровым блоком управления
 9)     Комплекты спутникового телевидения, игровые приставки с цифровым блоком управления
 10) Телевизоры, проекторы с цифровым блоком управления
 11) Цифровые фото- и видеокамеры, объективы к ним и оптическое фото- и кинооборудование с цифровым блоком управления
 12) Холодильники, морозильники, комбинированные холодильники-морозильники, посудомоечные, автоматические стиральные, сушильные и стирально-сушильные машины, кофемашины, кухонные комбайны, электрические и комбинированные газоэлектрические плиты, электрические и комбинированные газоэлектрические варочные панели, электрические и комбинированные газоэлектрические духовые шкафы, встраиваемые микроволновые печи, роботы-пылесосы, кондиционеры, электрические водонагреватели.
 13) Часы наручные и карманные механические, электронно-механические и электронные, с двумя и более функциями
 14) Инструмент электрифицированный (машины ручные и переносные электрические)
     II.          В течение 1 дня можно вернуть почти любые товары. Покупатель может проверить заказ и вернуть как товары с повреждениями или браком, так и товары, которые не подошли или не понравились.
   III.          Для товаров, на которые установлен гарантийный срок- в течение гарантийного срока, срока годности.
   IV.          Для товаров, на которые не установлен гарантийный срок - в пределах 2-х лет со дня получения товара при условии предоставления экспертного заключения о том, что недостаток возник до передачи товара Покупателю или по причинам, возникшим до этого момента.
 8.2.3.     При обнаружении брака в срок, указанный в п. 8.2.1 Оферты, Покупатель возвращает Товар изготовителю или Продавцу. Товар должен быть возвращен вместе с гарантийным талоном (при наличии) и копией Акта (заключения) сервисного центра.
 8.2.4.     Товары, для работы которых требуется заправка пожароопасным топливом (газ, бензин и т.д.), принимаются к возврату только при условии его полного удаления из резервуара или баллона, в котором оно содержится в процессе эксплуатации.
 8.2.5.     При возврате Покупателем Товара ненадлежащего качества Продавец возвращает ему стоимость возвращенного Товара, а также расходы на доставку от Покупателя возвращенного Товара в срок не позднее чем через 10 дней с даты получения Продавцом Товара ненадлежащего качества, при условии, что недостаток Товара является производственным и возник не по вине Покупателя. В случае если в Заказе Покупателя присутствуют товары надлежащего качества, стоимость доставки не компенсируется.
 8.2.6.     Обмен Товара ненадлежащего качества осуществляется путем возврата Товара Продавцу с последующей отменой Заказа или Товара, затем оформления нового Заказа. При этом отмена является техническим действием и не подразумевает отказ Продавца от исполнения договора. Денежные средства возвращаются Покупателю в полном объеме. Если на момент обращения Покупателя аналогичный Товар отсутствует в продаже у Продавца, Покупатель вправе отказаться от исполнения договора купли-продажи и потребовать возврата уплаченной за указанный Товар денежной суммы. Продавец возвращает денежную сумму за возвращенный Товар в установленный Законом срок.
 8.2.7.     При покупке комплекта товаров по акции, в рамках которой была предоставлена скидка на один из товаров или все товары в комплекте, товары ненадлежащего качества могут быть возвращены только в составе комплекта. Возврат отдельных товаров из комплекта невозможен, если иное не предусмотрено в правилах проведения акции.
 8.3.          Возврат денежных средств
 8.3.1.     В случае отмены Заказа или возврата Товара в порядке, предусмотренном разделом 8.2. настоящей Оферты, Продавец возвращает Покупателю денежные средства. Способ возврата денежных средств определяется в зависимости от способа, который был использован при оплате Товара.
 8.3.2.     Возврат денежных средств осуществляется одним из следующих способов:
 ·       при оплате Банковской картой (или Apple Pay / Google Pay / Samsung Pay) на сайте Агента путем перечисления денежных средств на Банковскую карту (или Apple Pay / Google Pay / Samsung Pay), с которой осуществлялась оплата;
 ·       при оплате Банковской картой (или Apple Pay / Google Pay / Samsung Pay) курьеру или в пункте выдачи заказов путем перечисления денежных средств по заявлению Покупателя на банковский счет;
 ·       при оплате Электронными деньгами (Яндекс. Деньги, Qiwi и прочие) путем перечисления денежных средств по заявлению Покупателя на электронный или банковский счет;
 ·       при оплате Банковским переводом путем зачисления денежных средств на банковский счет при указании банковских реквизитов.
 8.3.3.     В случае отмены Заказа или Товара, оплаченных банковской картой, до момента его получения, автоматически будет оформлен возврат на карту, с которой производилась оплата.
 8.4.          Порядок действий при нарушении продавцом условия об ассортименте (пересорте).
 8.4.1.     В случае передачи Товара в нарушении условия об ассортименте не применяются правила ст. 468 ГК РФ.
 8.4.2.     Если иное не предусмотрено настоящей Офертой, в случае обнаружения в Заказе Товара, не соответствующего заказанному ассортименту (пересорт), Покупатель вправе при передаче Заказа отказаться от данного Товара и потребовать замены на Товар в ассортименте, предусмотренном Заказом, либо возврата уплаченных денежных средств за фактически непереданный Товар.
 8.4.3.     Товар, переданный Покупателю в нарушение условия об ассортименте, подлежит возврату Продавцу. В случае если Покупатель принимает данный Товар, Продавец вправе потребовать от Покупателя оплаты данного Товара по цене, установленной Продавцом для данного Товара на Сайте на момент передачи Товара (в том числе в судебном порядке). Если фактически переданный Товар отсутствует в ассортименте, представленном на Сайте на момент передачи Товара, данный Товар оплачивается по цене, согласованной с Продавцом.
 8.4.4.     Замена Товара, не соответствующего Заказу по ассортименту, осуществляется путем оформления нового Заказа. В случае если Товар, не соответствующий ассортименту, был предварительно оплачен Покупателем, его стоимость возвращается на банковскую карту, с которой производилась оплата либо, способом, указанным в п. 8.3.2. Оферты. Если недостающий Товар не был оплачен Покупателем, он оплачивается любым доступным способом, выбранным Покупателем, при оформлении нового Заказа. 
 8.4.5.     В случае невозможности осуществить замену Товара, Продавец уведомляет об этом Покупателя посредством направления сообщения на электронный адрес, указанный Покупателем при регистрации, а денежные средства, фактически оплаченные за непереданный Товар, возвращаются в порядке, предусмотренном п. 8.3.2. Оферты.
 8.4.6.     Денежные средства, оплаченные Покупателем за фактически непереданный Товар, возвращаются на банковскую карту, с которой производилась оплата либо, в порядке, предусмотренном п. 8.3.2. Оферты. Возврат уплаченной за Товар суммы осуществляется тем способом, которым была произведена оплата.
 8.5.          Порядок действий при нарушении Продавцом условия о количестве.
 8.5.1.     Если иное не установлено настоящей Офертой, при передаче Заказа Покупатель обязан проверить количество Товаров в Заказе. Если при передаче Заказа Покупателем обнаружены расхождения по количеству Товара в Заказе, Покупатель обязан незамедлительно проинформировать об этом Продавца.
 8.5.2.     Если Продавец передал Покупателю меньшее количество Товара, чем определено Заказом (недовложение), Покупатель при передаче Заказа вправе принять Товар в части, соответствующей Заказу, и потребовать передать недостающее количество Товара, либо, если недостающий Товар был оплачен, отказаться от Заказа в части недостающего Товара и потребовать возврата денежных средств за недостающий Товар.
 8.5.3.     В случае если недостающий Товар был предварительно оплачен Покупателем, его стоимость автоматически возвращается на банковскую карту, с которой была произведена оплата либо в порядке, предусмотренном в п. 8.3.2. Оферты
 8.5.4.     Передача недостающего Товара осуществляется посредством оформления нового Заказа. Если цена Товара увеличилась, перед оформлением нового Заказа Покупателю необходимо связаться с Продавцом. Если недостающий Товар не был ранее оплачен Покупателем, либо его стоимость была возвращена на банковскую карту, он оплачивается любым доступным способом при оформлении нового Заказа.
 8.5.5.     В случае невозможности передать недостающий Товар, Продавец уведомляет об этом Покупателя посредством направления сообщения на электронный адрес, указанный Покупателем при регистрации. Возврат уплаченной за Товар суммы осуществляется способом предусмотренном в п. 8.3.2. Оферты.
 8.5.6.     В случае нарушения Покупателем п. 8.5.1 в части информирования Продавца, Продавец вправе отказать Покупателю в удовлетворении претензий по количеству переданного Товара.
  
 9.     ОТВЕТСТВЕННОСТЬ
 9.1.          Покупатель несет полную ответственность за предоставление неверных, неправильных или неточных сведений, повлекшее за собой невозможность надлежащего исполнения Продавцом своих обязательств перед Покупателем.
 9.2.          Продавец не несет ответственности за точность и правильность информации, предоставляемой Покупателем при Верификации.
 9.3.          Продавец не несет ответственности за прямые или косвенные убытки, возникшие в результате ненадлежащего использования Товаров, приобретенных на Сайте.
 9.4.          Продавец не несет ответственность за ущерб, причиненный Покупателю, третьим лицам в результате использования или невозможности использования Сайта или отдельных его функций, в том числе из-за возможных ошибок или сбоев в работе Сайта, за исключением случаев, прямо предусмотренных законодательством.
 9.5.          Продавец вправе переуступать либо каким-либо иным способом передавать свои права и обязанности, вытекающие из его отношений с Покупателем, третьим лицам.
 9.6.          Продавец не несет ответственности за убытки, которые Покупатель может понести в результате того, что его номер телефона перешел в собственность другого лица, в том числе в результате длительного (от 3х месяцев) неиспользования такого номера телефона Покупателем.
 9.7.          Стороны Договора освобождаются от ответственности за полное или частичное неисполнение своих обязательств, если такое неисполнение явилось следствием действия обстоятельств непреодолимой силы, которые Стороны не могли предвидеть и предотвратить разумными мерами.
  
 10.  КОНФИДЕНЦИАЛЬНОСТЬ И ЗАЩИТА ИНФОРМАЦИИ
 10.1.       Настоящий раздел устанавливает правила обеспечения конфиденциальности и порядок обработки персональных данных (Сокращенно - ПДн) субъектов ПДн (далее также именуемых «Покупатели») Продавцом, при посещении и использовании Сайта Покупателем, регистрации Аккаунта, заполнении формы обратной связи, предоставлении ПДн иным Покупателям Сайта, а также совершения иных действий, предусмотренных Офертой. Обработка персональных данных осуществляется в форме сбора, хранения, уточнения и уничтожения ПДн в Информационных системах Продавца.
 10.2.       Политика Продавца в отношении обработки ПДн Покупателей на Сайте состоит в том, что ПДн должны обрабатываться только в случаях, установленных законом, исходя из основных направлений деятельности Продавца и с учетом баланса интересов Продавца и субъектов ПДн. Обработка ПДн Продавцом осуществляется с учетом необходимости обеспечения защиты прав и свобод субъектов ПДн, в том числе защиты права на неприкосновенность частной жизни, личную и семейную тайну.
 10.3.       Передача (обмен и т.д.) ПДн осуществляется на основании согласия субъекта ПДн, в том числе в виде выражения согласия посредством функционала Сайта или в форме документа, подписанного простой электронной подписью, а также в иных случаях, предусмотренных законодательством.
 10.4.       Покупатель, осуществляя регистрацию Аккаунта, заполняя Форму обратной связи с использованием Сайта, путем проставления галочки в соответствующем разделе, подтверждает, что ознакомился с Офертой и предоставил согласие на обработку ПДн.
 10.5.       Передача ПДн государственным органам производится в соответствии с требованиями действующего законодательства и настоящим Положением.
 10.6.       ПДн Покупателей могут обрабатываться посредством Сайта в следующих целях:
 •          осуществление и поддержание связи (коммуникации) между Покупателями;
 •          осуществление и поддержание связи (коммуникации) между Продавцом и Покупателем;
 •          продвижение услуг и направление рассылки, в том числе путем направления на адрес электронной почты Покупателя сообщений информационного, рекламного и иного характера;
 •          регистрация Покупателя на Сайте, включая, но не ограничиваясь действиями по внесению личных данных Покупателя, Верификацию Покупателя;
 •          использование и управление Аккаунтом Покупателя;
 •          использование личных данных Покупателя при оставлении отзывов о Товарах на Сайте;
 •          заполнение формы обратной связи посредством функционала Сайта;
 •          исполнение Продавцом обязательств перед Покупателями согласно условиям настоящей Оферты;
 •          улучшения качества обслуживания и модернизации Сайта Продавцом;
 •          осуществление правосудия, в случае поступления в адрес Продавца соответствующего запроса уполномоченных органов;
 •          реализация партнерских программ с третьими лицами;
 •          иных целях, определяемых в порядке, установленном в настоящей Оферте.
 Конкретные цели также могут быть указаны в заполняемой Покупателем форме обратной связи.
 10.7.       Продавец не проверяет достоверность предоставляемой Покупателем информации, а также дееспособность Покупателя.
 10.8.       При разработке форм обратной связи Продавец обеспечивает соответствие содержания и объема обрабатываемых ПДн заявленным целям обработки.
 10.9.       Продавец никогда не собирает ПДн в тайне от Покупателей. На Сайте могут применяться сервисы ведения статистики посещений, определения уровня заинтересованности Покупателей и иные подобные инструменты, собирающие и анализирующие только обезличенную информацию (не ПДн). Сайт может в автоматическом режиме сохранять на устройства Покупателей cookie-файлы (небольшие текстовые файлы технического характера), не содержащие ПДн и не используемые для установления личности Покупателя. Если Покупатель не желает сохранять указанные файлы на своем устройстве, он может в любое время изменить настройки устройства и удалить уже сохраненные файлы с использованием стандартного функционала устройства Покупателя.
 10.10.   Также под технической информацией понимается информация, которая автоматически передается Продавцу в процессе использования Сайта с помощью установленного на мобильном устройстве Покупателя программного обеспечения, а именно:
 •          Данные об активности Покупателя на Сайте, в частности о посещенных страницах, дате и времени переходов и пр.;
 •          Информация об устройстве, с помощью которого Покупатель использовал Сайт: IP-адресе и тип устройства, операционной системе, а также о его уникальном идентификаторе;
 •          Данные о взаимодействии с демонстрируемыми вне Сайта рекламными объявлениями Продавца, их количестве, частоте и глубине просмотра.
 10.11.   Обработка ПДн на Сайте осуществляется способами с использованием и без использования средств автоматизации в течение сроков, необходимых для достижения целей обработки. Условием прекращения обработки Продавцом ПДн, может являться достижение целей их обработки, отзыв согласия субъекта ПДн на обработку его ПДн, прекращение деятельности Продавца, закрытие Сайта или изменение его функционала.
 10.12.   С использованием функционала Сайта Продавец осуществляет сбор, запись, извлечение и использование ПДн Покупателей. При необходимости Продавец может осуществлять трансграничную передачу ПДн в пределах, установленных Применимым законодательством. Продавец проверяет, обеспечивает ли государство, на территорию которого осуществляется передача ПДн, адекватную защиту прав субъектов ПДн, до начала осуществления трансграничной передачи ПДн.
 10.13.   С целью осуществления коммуникаций c Покупателями Продавец обеспечивает функционирование форм обратной связи и обработку поступивших через них обращений. Обращения не сохраняются на Сайте, а поступают уполномоченным сотрудникам Продавца для последующей работы. При этом Продавец обрабатывает только ПДн, внесенные Покупателями в соответствующие поля форм обратной связи. К примеру, такими данными могут быть (в зависимости от конкретной формы обратной связи): ФИО; контактные данные; данные, содержащиеся в сообщении и приложенных к нему документах и другие. Реагирование на обращения Покупателя осуществляется сотрудниками Продавца как с использованием, так и без использования функционала Сайта.
 10.14.   Для достижения заявленных целей обработки Продавец может сообщать ПДн третьим лицам, в том числе своим аффилированным компаниям, а также поручать обработку ПДн другим лицам на основании договора, содержащего условия о конфиденциальности и иные обязательные положения, предписанные Применимым законодательством.
 10.15.   ПДн Покупателей являются конфиденциальной информации в соответствии с применимым законодательством. Продавец принимает меры, необходимые и достаточные для обеспечения выполнения обязанностей, предусмотренных применимым законодательством в области ПДн.
 10.16.   Обеспечение безопасности ПДн достигается Продавцом, в частности путем:
 ·       определения угроз безопасности ПДн при их обработке в информационных системах ПДн;
 ·       применения организационных и технических мер по обеспечению безопасности ПДн при их обработке в информационных системах ПДн, необходимых для выполнения требований к защите ПДн, исполнение которых обеспечивает установленные законодательством уровни защищенности ПДн;
 ·       применения прошедших в установленном порядке процедуру оценки соответствия средств защиты информации;
 ·       оценке эффективности принимаемых мер по обеспечению безопасности ПДн до ввода в эксплуатацию информационных систем ПДн;
 ·       учета машинных носителей ПДн;
 ·       обнаружения фактов несанкционированного доступа к персональным данным и принятия мер;
 ·       восстановления ПДн, модифицированных или уничтоженных вследствие несанкционированного доступа к ним;
 ·       установления правил доступа к персональным данным, обрабатываемым в информационных системах ПДн, а также обеспечения регистрации и учета всех действий, совершаемых с персональными данными в информационных системах ПДн;
 ·       контроля за принимаемыми мерами по обеспечению безопасности ПДн и уровня защищенности информационных систем ПДн.
 10.17.   При достижении целей обработки ПДн, а также в случае отзыва субъектом ПДн согласия на их обработку, ПДн подлежат уничтожению, кроме случаев, установленных Применимым законодательством.
  
 11.  ДОПОЛНИТЕЛЬНЫЕ УСЛОВИЯ
 11.1.       Отношения между Покупателем и Продавцом регулируются законодательством Российской Федерации.
 11.2.       Покупатель подтверждает, что условия настоящего Договора ему понятны, и он принимает их и в полном объёме без каких-либо оговорок.
 11.3.       В случае возникновения споров и разногласий Стороны будут решать их путем переговоров, при не достижении соглашения, споры подлежат рассмотрению в суде соответствии с законодательством Российской Федерации.
 11.4.       Во всем ином, что не предусмотрено Договором, Стороны руководствуются законодательством Российской Федерации.
 11.5.       В случае признания судом недействительности какого-либо из положений Договора, остальные положения Договора продолжают действовать.
 `